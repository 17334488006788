
import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AccountModal, ProModal } from '@/lazy-load-components';

import { ImpressionTrackingEvents } from '@/enums/events';
import type { WebLocale } from '@/enums/web-locale';
import { UrlMetadataResponse } from '@/interfaces/responses/url-metadata';

import { AdProvider, adSlots, getAdProvider, loadAd, removeAd } from '@/helpers/ad/ad-helper';
import { getYieldloveMaxSlotSize } from '@/helpers/ad/yieldlove-helper';
import { ModalHelper } from '@/helpers/modal-helper';
import { isProWebCountry, saveLastProView } from '@/helpers/pro-web-helper';
import { SnowplowContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { TrafficHelper } from '@/helpers/traffic-helper';
import { getViewport } from '@/helpers/ui-helper';
import { lowPriorityCallback } from '@/helpers/request-idle-helper';

const Language = namespace('language');
const Routing = namespace('routing');
const User = namespace('user');

Vue.use(VueObserveVisibility);

interface TrackEventArgs {
	action?: 'click' | 'impression';
	label: string;
	property?: string;
	nonInteraction?: boolean;
	contexts?: SnowplowContext[];
}

/**
 * the component will be blocking some ad space already on the server.
 * the server doesn't know the viewport of the user, therefore it assumes 420px (yiellove-helper.ts:99).
 * the first 50 percentile are between 320-420px. so we priotize mobile first.
 */
@Component({ name: 'AdUnit' })
export default class AdUnit extends Vue {
	isShown = false;

	@Prop({ type: Boolean, default: true }) allowAdRemoval: boolean;
	// yieldlove
	@Prop({ type: String, required: true }) adPosition: string;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	@Language.State webLocale: WebLocale;
	@Language.Getter language: string;

	@Routing.State activeRoute: any;
	@Routing.State urlMetadata: UrlMetadataResponse;
	@Routing.State urlMetadataPromise: Promise<UrlMetadataResponse> | null;

	@User.State jwLoginId: string | undefined;
	@User.State isPremium: boolean;

	isSlotFilled: null | boolean = null;

	get adProvider() {
		const isSportAdUnit = this.adPosition?.includes('sport');
		return getAdProvider(this.webLocale, isSportAdUnit);
	}

	get iframeAdProviders() {
		return [AdProvider.YIELDLOVE, AdProvider.ADSENSE, AdProvider.GAM];
	}

	get slotId(): string {
		switch (this.adProvider) {
			case AdProvider.YIELDLOVE:
			case AdProvider.GAM:
				return this.yieldloveSlotId;
			case AdProvider.ADSENSE:
				return this.adSlot.adsenseSlot;
			default:
				return '';
		}
	}

	get yieldloveSlotId() {
		return `jw_${this.webLocale}_${this.adPosition}`;
	}

	get adSlot() {
		const max = getYieldloveMaxSlotSize(this.webLocale, this.yieldloveSlotId);

		const adSlot = adSlots
			.filter(adSlot => adSlot.name === `jw_{WEBLOCALE}_${this.adPosition}`)
			.map(adSlot => adSlot.sizes)
			// flatten
			.reduce((root: any, item: any) => {
				root = root.concat(item);
				return root;
			}, [])
			.find((slot: any) => slot.x === max.x && slot.y === max.y);
		return adSlot || {};
	}

	// @todo would be great to refactor these ads to only use 1 config instead of so many different ones
	get config() {
		switch (this.adProvider) {
			case AdProvider.ADSENSE:
				return {
					adClient: 'ca-pub-3179068936475881',
				};

			default:
				return {};
		}
	}

	get adsenseStyle() {
		return {
			display: 'inline-block',
			width: this.adSlot.x + 'px',
			height: this.adSlot.y + 'px',
		};
	}

	get adUnitRef(): Element | undefined {
		return this.$refs['adUnit'] as Element;
	}

	async mounted() {
		const urlData = await this.urlMetadataPromise!;
		const cb = () => this.setShown(urlData);
		lowPriorityCallback(this, cb);
		/**
		 * for ads which are being rendered in an iframe we will not be able to track the clicks using
		 * a simple onClick event, that's why we added the "blur" listener which will trigger the AdClick
		 * event once the window loses focus (when the user click on the Ad) and the new active element
		 * is the iframe withing this adUnit check the triggerAdClickOnBlur function.
		 *
		 * we will keep the onClick event for the other ads like banners which are not being rendered in a iframe,
		 * and no need for the blur listener for those.
		 */
		if (this.iframeAdProviders.includes(this.adProvider)) {
			window.addEventListener('blur', this.triggerAdClickOnBlur);
		}
	}

	beforeDestroy() {
		window.removeEventListener('blur', this.triggerAdClickOnBlur);
	}

	destroyed() {
		removeAd(this.webLocale, this.slotId, this.adProvider);
	}

	triggerAdClickOnBlur = () => {
		if (this.adUnitRef?.querySelector('iframe') === document.activeElement) {
			this.onAdClick();
		}
	};

	onMouseLeaveAd() {
		/**
		 * once the user clicks on the ad the window loses focus and we will not be able to track the second click
		 * unless the user clicks outside the Ad , to fix this issue once the mouse leaves the Ad the window
		 * will gain focus.
		 */
		if (this.adUnitRef?.querySelector('iframe') === document.activeElement) {
			window.focus();
		}
	}

	openModal(e: Event) {
		e.stopPropagation();
		const location = 'RemoveAds';
		if (isProWebCountry(this.webLocale)) {
			ModalHelper.openModal(ProModal, {}, {}, location, true);
			saveLastProView(location);
		} else {
			ModalHelper.openModal(AccountModal, { closable: true }, { cssClass: 'account-modal' }, 'AdUnit');
		}
		this.$emit('removeAdClicked');
	}

	@Watch('activeRoute')
	async onRouteChange() {
		const urlData = await this.urlMetadataPromise!;
		this.setShown(urlData);
	}

	@Watch('jwLoginId')
	async onLoginIdChange() {
		const urlData = await this.urlMetadataPromise!;
		this.setShown(urlData);
	}

	get isAdSpaceShown() {
		const userStatus = isProWebCountry(this.webLocale) ? !this.isPremium : !this.jwLoginId;
		return (
			// temporary hide adSense
			this.adProvider !== AdProvider.ADSENSE &&
			userStatus &&
			this.isCorrectViewport() &&
			!TrafficHelper.isCampaignTraffic() &&
			!this.urlMetadata?.html_content?.exclude_from_ads
		);
	}

	private isAdAtTop(): boolean {
		return (
			[AdProvider.YIELDLOVE, AdProvider.ADSENSE, AdProvider.GAM].includes(this.adProvider) &&
			this.adPosition === 'top'
		);
	}

	/**
	 * check ad unit `top` is in smartphone viewport.
	 */
	private isCorrectViewport(_stateName = this.activeRoute.name) {
		return getViewport() !== 'smartphone' || !this.isAdAtTop();
	}

	/**
	 * main point to set ad as shown.
	 * @param {boolean} isShown
	 */
	private async setShown(_urlData: UrlMetadataResponse) {
		const isShown = this.isAdSpaceShown;
		if (process.client) {
			// the server will block the ads in SSR and the client will then load the ads into that space
			// this way we prevent cumulative layout shift
			if (!this.isShown && isShown) {
				loadAd(this.webLocale, this.slotId, this.adProvider, filled => (this.isSlotFilled = filled));
			} else if (this.isShown && !isShown) {
				removeAd(this.webLocale, this.slotId, this.adProvider);
			}
		}

		this.isShown = isShown;
	}

	onAdImpression(isVisible: boolean, _entry: any) {
		if (!isVisible || !this.isAdSpaceShown) return;

		const event: TrackEventArgs = { action: 'impression', label: 'banner', nonInteraction: true };
		TrackingHelper.trackEvent(ImpressionTrackingEvents.AD, event, this.additionalContexts);
	}

	onAdClick() {
		const event: TrackEventArgs = { action: 'click', label: 'banner' };
		TrackingHelper.trackEvent(ImpressionTrackingEvents.AD, event, this.additionalContexts);
		this.$emit('adClicked');
	}
}
